<template>
    <div class="dashboard">
        <router-link :to="{ name: 'forums' }" class="back-btn mb-3">
            <b-icon icon="chevron-left"></b-icon>
            <span>к списку событий</span>
        </router-link>
        <main-forum-info :isEdit="isEdit"/>
    </div>
</template>

<script>
    import MainForumInfo from '@/components/forms/forum/MainForumInfo'

    export default {
        name: "forum-add",
        components: {
            MainForumInfo,
        },
        data(){
            return {
                isEdit: false,
            }
        }
    }
</script>